import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import FTSVG from "../../../assets/svg/ft.svg";
import ORSVG from "../../../assets/svg/or.svg";
import SFSVG from "../../../assets/svg/sf.svg";
import MapView from "./map-view/MapView";
import styles from "./PortfolioDetail.module.scss";

const PortfolioDetail = (props) => {
  const params = useParams();

  const [p, setProperty] = useState({});
  const [showMap, setShowMap] = useState(false);

  const [currentImg, setCurrentImg] = useState(0);

  const getProperty = async () => {
    let data = await axios.get(
      `${process.env.REACT_APP_AF_BASE_URL}/getProperty/${params.id}`
    );
    let imgs = data.data.imgs.filter((img) => img.imageBaseUrl && img.filePath);
    data.data.imgs = imgs;
    // console.log(data);
    setProperty(data.data);
  };

  useEffect(() => {
    getProperty();
    // eslint-disable-next-line
  }, []);

  const getImageUrl = (img, size) => {
    if (!img?.imageBaseUrl)
      return `https://ik.imagekit.io/qimtirncjhjmy/${
        size === "main" ? "tr:h-546,w-729" : "tr:h-66,w-66"
      },cm-pad_resize/picture_DvU3Z_OnKnn.png`;

    if (size === "main")
      return `${img.imageBaseUrl}/tr:h-546,w-729${img.filePath}`;

    if (size === "thumb")
      return `${img.imageBaseUrl}/tr:h-66,w-66${img.filePath}`;
  };
  return (
    <>
      {" "}
      <div className={`df ${styles.detailsPage}`}>
        <div className={styles.leftSec}>
          <h2 className={`fflm fs26`}>{p.propertyName}</h2>
          <p className={`ffsbook fs23 ${styles.addressP}`}>
            {p.propertyAddress}
          </p>

          <div className={styles.imgsWrpr}>
            {p?.imgs ? (
              <>
                <div className={styles.mainImgWrpr}>
                  <div className={`ffsm fs20 df acc ${styles.tag}`}>
                    {p.propertyType}
                  </div>
                  <img
                    src={getImageUrl(p?.imgs[currentImg], "main")}
                    alt="img"
                    className={styles.mainImg}
                  />
                </div>

                <div className={`ds acsa ${styles.thumbsSec}`}>
                  {p?.imgs ? (
                    <>
                      {p.imgs.map((img, i) => (
                        <img
                          key={i}
                          className={`${styles.thumb} ${
                            currentImg === i ? styles.selected : ""
                          }`}
                          onClick={() => setCurrentImg(i)}
                          src={getImageUrl(img, "thumb")}
                          alt="img"
                        />
                      ))}
                    </>
                  ) : undefined}
                </div>
              </>
            ) : undefined}
          </div>
        </div>

        <div className={styles.rightSec}>
          <div className={`ffsbook fs16 ${styles.descWrpr}`}>
            <p>{p.shortDescription}</p>
          </div>

          <div className={`${styles.detsWrpr}`}>
            <div className={`df ${styles.sec}`}>
              <div className={`df acc ${styles.iconWrpr}`}>
                <SFSVG></SFSVG>
              </div>
              <div>
                <p className={`ffsbook fs16`}>
                  <span className={`ffsr ${styles.label}`}>Square Foot:</span>{" "}
                  {p.squareFootage}
                </p>
              </div>
            </div>
            {p.occupancyRate ? (
              <div className={`df ${styles.sec}`}>
                <div className={`df acc ${styles.iconWrpr}`}>
                  <ORSVG></ORSVG>
                </div>
                <div>
                  <p className={`ffsbook fs16`}>
                    <span className={`ffsr ${styles.label}`}>Occupancy:</span>{" "}
                    {p.occupancyRate}%
                  </p>
                </div>
              </div>
            ) : undefined}

            {p.featuredTenants ? (
              <div className={`df ${styles.sec}`}>
                <div className={`df acc ${styles.iconWrpr}`}>
                  <FTSVG></FTSVG>
                </div>
                <div>
                  <p
                    className={`ffsbook fs16`}
                    style={{ paddingRight: "14px" }}
                  >
                    <span className={`ffsr ${styles.label}`}>
                      Featured Tenants:
                    </span>{" "}
                    <br />
                    {p.featuredTenants}
                  </p>
                </div>
              </div>
            ) : undefined}
          </div>

          <div className={styles.ftrSec}>
            {p.pdfURL ? (
              <a
                rel="noreferrer"
                className={`ffsm fs14 df acc`}
                target="_blank"
                href={p.pdfURL}
              >
                Download Site Plan
              </a>
            ) : undefined}
            {!isNaN(Number(p.propertyLat)) && !isNaN(Number(p.propertyLong)) ? (
              <button className={`ffsm fs14`} onClick={() => setShowMap(true)}>
                View on map
              </button>
            ) : undefined}
          </div>
        </div>
        {showMap ? (
          <MapView
            lat={Number(p.propertyLat)}
            lng={Number(p.propertyLong)}
            close={() => setShowMap(false)}
          ></MapView>
        ) : undefined}
      </div>
      <div className="df acsa page-devider-sec">
        <div className="line-marker" />
        <Link to="/contact" className="ffsm fs16 centerpiece">
          Submit a Property
        </Link>
        <div className="line-marker" />
      </div>
    </>
  );
};

export default PortfolioDetail;
