import React, {useEffect, useState} from "react";
import {Link, NavLink, useHistory, withRouter} from "react-router-dom";
import LogoOnLight from "../../assets/svg/logo-on-light.svg";
import Logo from "../../assets/svg/logo.svg";
import {Manu} from "../../assets/svg/manu.svg";
import {MenuCloseSVG} from "../../assets/svg/menu-close.svg";
import "./Header.scss";

const Header = (props) => {
	const history = useHistory();

	const [pageScroll, setPageScroll] = useState(undefined);
	const [showMobileNav, setShowMobileNav] = useState(false);

	let theme =
		history.location.pathname === "/" && Number(pageScroll) > -350
			? "light"
			: "dark";

	let isAdmin = history.location.pathname === "/admin" ? true : false;

	const getScroll = () => {
		setPageScroll(props.pageRef?.current?.getBoundingClientRect().top);
	};
	useEffect(() => {
		window.addEventListener("scroll", getScroll);
		return () => {
			window.removeEventListener("scroll", getScroll);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{/* {console.log(props.pageRef?.current?.getBoundingClientRect().top)} */}
			{!isAdmin ? (
				<div className={`header ${theme}`}>
					<div className="df acsa cntnt-wrpr">
						<Link to="/">
							<img
								src={theme === "light" ? Logo : LogoOnLight}
								alt="logo"
							/>
						</Link>

						<div className="mobile-phone ffsm fs16 mla mra">
							845.579.3033
						</div>

						<div
							className={`df acsa mla navs-wrpr ${
								showMobileNav ? "show" : ""
							}`}
						>
							<div className="df acsa mla all-navs">
								<NavLink
									onClick={() => setShowMobileNav(false)}
									activeClassName="selected"
									exact
									className="df acsa ffsm fs16"
									data-text="Home"
									to="/"
								>
									Home
								</NavLink>
								<NavLink
									onClick={() => setShowMobileNav(false)}
									activeClassName="selected"
									exact
									className="df acsa ffsm fs16"
									data-text="Services"
									to="/services"
								>
									Services
								</NavLink>

								<NavLink
									onClick={() => setShowMobileNav(false)}
									activeClassName="selected"
									exact
									className="df acsa ffsm fs16"
									data-text="About"
									to="/about"
								>
									About
								</NavLink>

								<NavLink
									onClick={() => setShowMobileNav(false)}
									activeClassName="selected"
									exact
									className="df acsa ffsm fs16"
									data-text="Contact"
									to="/contact"
								>
									Contact
								</NavLink>

								<NavLink
									onClick={() => setShowMobileNav(false)}
									activeClassName="selected"
									exact
									className="df acsa ffsm fs16"
									data-text="Portfolio"
									to="/portfolio"
								>
									Portfolio
								</NavLink>

								<a
									className="desktop-only ffsm fs16"
									data-text="845.579.3033"
									href="tel:845.579.3033"
								>
									845.579.3033
								</a>
							</div>
							<a
								href="https://propup.twa.rentmanager.com/"
								onClick={() => setShowMobileNav(false)}
								className="ffsm fs16 btn"
							>
								Tenant Portal
							</a>
						</div>

						<div
							onClick={() => setShowMobileNav(!showMobileNav)}
							className="mobile-manu-wrpr df acc mla"
						>
							{showMobileNav ? (
								<MenuCloseSVG></MenuCloseSVG>
							) : (
								<Manu></Manu>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className={`header ${theme}`}>
					<div className="df acsa cntnt-wrpr">
						<Link to="/">
							<img
								src={theme === "light" ? Logo : LogoOnLight}
								alt="logo"
							/>
						</Link>
						<div
							className={`df acsa mla navs-wrpr ${
								showMobileNav ? "show" : ""
							}`}
						>
							<Link
								to="/admin/add"
								// onClick={() => setShowMobileNav(false)}
								className="ffsm fs16 btn"
							>
								Add Property
							</Link>
						</div>
					</div>
				</div>
			)}

			{/* <div className="wrapper">
				<div className="bg">
					<div className="el"></div>
				</div>
			</div> */}
		</>
	);
};

export default withRouter(Header);
