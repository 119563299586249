// *****************************************
// *****************************************

import Slider from "@reach/slider";
import "@reach/slider/styles.css";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import "react-image-crop/dist/ReactCrop.css";
import Portal from "../../../../portal/Portal";
import styles from "./UploadPopup.module.scss";
import { getCroppedImg } from "./utils";

const UploadPopup = (props) => {
  const [imageSrc, setImageSrc] = useState(props.imageUrl || null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  // const [croppedImage, setCroppedImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.imageUrl) setImageSrc(props.imageUrl);
  }, [props.imageUrl]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      // setCroppedImage(croppedImage);
      props.setImageBlob(croppedImage.blob);
      props.setBase64Image(croppedImage.base64);
      setLoading(false);
      props.close(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
    // eslint-disable-next-line
  }, [imageSrc, croppedAreaPixels]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };
  return (
    <Portal>
      <div className={styles.uploadPopup}>
        <div
          className={`df acc ${styles.closeWrpr}`}
          onClick={() => props.close(false)}
        />
        {imageSrc || props.imageUrl ? (
          <>
            <div className={styles.cropSection}>
              <div className={styles.cropWrapper}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  zoom={zoom}
                />
              </div>
              <div className={`df acsa ${styles.sliderWrapper}`}>
                <div className={styles.innerSliderWrapper}>
                  <Slider
                    min={1}
                    max={4}
                    step={0}
                    onChange={(range) => setZoom(range)}
                  />
                </div>
              </div>
            </div>
            <div className={`df acsa ${styles.ftr}`}>
              <div className="df mla">
                <label
                  className={`df acc ffsm fs14 ${styles.changeBtn}`}
                  for="file_input"
                >
                  Change image
                </label>

                <p
                  className={`df acc ffsm fs14 ${styles.setBtn} ${
                    loading ? styles.disabled : ""
                  }`}
                  onClick={() => !loading && showCroppedImage()}
                >
                  Set image
                </p>
              </div>
            </div>
          </>
        ) : (
          // setBtn
          <>
            <label className={`ffsr fs14 ${styles.selectBtn}`} for="file_input">
              Select image
            </label>
          </>
        )}

        <input
          id="file_input"
          style={{ display: "none" }}
          type="file"
          onChange={onFileChange}
          accept="image/*"
        />
        {/* import Cropper from 'react-easy-crop' */}

        {/* ************************************ */}
        {/* ************************************ */}
        {/* ************************************ */}
        {/* --------- react-image-crop --------- */}
        {/* ************************************ */}
        {/* ************************************ */}
        {/* ************************************ */}
        {/* <div className={styles.inputWrpr}>
					<input
						type="file"
						accept="image/*"
						onChange={onSelectFile}
					/>
				</div> */}

        {/* <ReactCrop
					src={upImg}
					onImageLoaded={onLoad}
					crop={crop}
					onChange={(c) => setCrop(c)}
					onComplete={(c) => setCompletedCrop(c)}
				/> */}

        {/* <div>
					<canvas
						ref={previewCanvasRef}
						// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
						style={{
							width: Math.round(completedCrop?.width ?? 0),
							height: Math.round(completedCrop?.height ?? 0),
						}}
					/>
				</div> */}

        {/* ************************************ */}
        {/* ************************************ */}
        {/* ************************************ */}
        {/* --------- react-image-crop --------- */}
        {/* ************************************ */}
        {/* ************************************ */}
        {/* ************************************ */}
      </div>
    </Portal>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default UploadPopup;
