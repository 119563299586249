export const Manu = (props) => {
	return (
		<svg
			width="30"
			height="24"
			viewBox="0 0 30 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.13208 3H28.8679C29.4928 3 30 2.328 30 1.5C30 0.672 29.4928 0 28.8679 0H1.13208C0.50717 0 0 0.672 0 1.5C0 2.328 0.50717 3 1.13208 3Z"
				fill="#F2C94C"
			/>
			<path
				d="M1.13208 13.5H28.8679C29.4928 13.5 30 12.828 30 12C30 11.172 29.4928 10.5 28.8679 10.5H1.13208C0.50717 10.5 0 11.172 0 12C0 12.828 0.50717 13.5 1.13208 13.5Z"
				fill="#F2C94C"
			/>
			<path
				d="M1.13208 24H28.8679C29.4928 24 30 23.328 30 22.5C30 21.672 29.4928 21 28.8679 21H1.13208C0.50717 21 0 21.672 0 22.5C0 23.328 0.50717 24 1.13208 24Z"
				fill="#F2C94C"
			/>
		</svg>
	);
};
