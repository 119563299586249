export const EditSVG = (props) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#editSVG)">
			<path
				d="M15.8177 3.93315L12.0663 0.181964C11.9498 0.0654983 11.7917 0 11.6267 0C11.6265 0 11.6265 0 11.6263 0C11.4614 0.000218744 11.3032 0.0659046 11.1867 0.182589L1.2388 10.1512C1.15528 10.2352 1.09746 10.3409 1.07178 10.4565L0.0148047 15.2442C-0.0307879 15.4514 0.0322105 15.6678 0.182457 15.8178C0.300172 15.9358 0.458918 16 0.622039 16C0.666819 16 0.711568 15.9953 0.75613 15.9851L5.54357 14.9279C5.65901 14.9024 5.76491 14.8442 5.84863 14.7607L15.8173 4.81297C15.9342 4.69651 15.9999 4.53817 15.9999 4.3732C15.9999 4.20824 15.9344 4.04983 15.8177 3.93315ZM5.09861 13.7532L1.43921 14.5613L2.24728 10.9017L11.6274 1.50193L14.4984 4.37314L5.09861 13.7532Z"
				fill="#222F33"
				fillOpacity="0.5"
			/>
			<path
				d="M9.84885 2.40163L8.96973 3.28076L12.7208 7.03186L13.5999 6.15274L9.84885 2.40163Z"
				fill="#222F33"
				fillOpacity="0.5"
			/>
			<path
				d="M2.11789 10.1512L1.23877 11.0303L4.9692 14.7607L5.84833 13.8816L2.11789 10.1512Z"
				fill="#222F33"
				fillOpacity="0.5"
			/>
		</g>
		<defs>
			<clipPath id="editSVG">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
