import React, { Suspense, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import AdminPage from "./components/admin/Admin";
import Header from "./components/header/Header";
import PortfolioDetail from "./components/portfolio/portfolio-detail/PortfolioDetail";
import ScrollIntoView from "./scrollIntoView";

const ContactUs = React.lazy(() =>
  import("./components/contact--us/ContactUs")
);
const Footer = React.lazy(() => import("./components/footer/Footer"));
const HomePage = React.lazy(() => import("./components/home/Home"));
const Services = React.lazy(() => import("./components/services/Services"));
const AboutPage = React.lazy(() => import("./components/about/About"));
const Portfolio = React.lazy(() => import("./components/portfolio/Portfolio"));

function App() {
  const pageRef = useRef(null);
  return (
    <div className="App" ref={pageRef}>
      <Suspense fallback={<p>Loading</p>}>
        <Header pageRef={pageRef} />

        {/* {console.log(history)} */}
        <ScrollIntoView>
          <Switch>
            <Route
              path="/admin"
              component={(props) => <AdminPage {...props} />}
            />
            <Route
              exact
              path="/services"
              component={(props) => <Services {...props} />}
            />
            <Route
              exact
              path="/about"
              component={(props) => <AboutPage {...props} />}
            />
            <Route
              exact
              path="/contact"
              component={(props) => <ContactUs {...props} />}
            />
            <Route
              exact
              path="/portfolio/:id"
              component={(props) => <PortfolioDetail {...props} />}
            />

            <Route
              exact
              path="/portfolio"
              component={(props) => <Portfolio {...props} />}
            />
            <Route
              exact
              path="/"
              component={(props) => <HomePage {...props} />}
            />
          </Switch>
        </ScrollIntoView>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;

// Test deploy
