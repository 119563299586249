// import {
// 	GoogleMap,
// 	Marker,
// 	withGoogleMap,
// 	withScriptjs,
// } from "react-google-maps";
import GoogleMapReact from "google-map-react";
import React from "react";
import MapPinSVG from "../../../../assets/svg/pin.svg";
import styles from "./MapView.module.scss";

const Marker = ({text}) => (
	<div
		className={styles.mapMarker}
		style={{
			display: "inline-flex",
			transform: "translate(-50%, -50%)",
		}}
	>
		<img src={MapPinSVG} height="42px" alt="img" />
	</div>
);

class SimpleMap extends React.Component {
	render() {
		return (
			<GoogleMapReact
				defaultCenter={{lat: this.props.lat, lng: this.props.lng}}
				defaultZoom={15}
				bootstrapURLKeys={{
					key: "AIzaSyB1TfLnhU74lHcHUYnQ8W1fCKh3zV_JA2o",
				}}
			>
				<Marker
					lat={this.props.lat}
					lng={this.props.lng}
					text={this.props.text}
				/>
			</GoogleMapReact>
		);
	}
}

const MapView = (props) => (
	<div className={styles.mapViewPage}>
		<div className={styles.mapView}>
			<div
				className={`df acc ${styles.closeBtn}`}
				onClick={() => props.close()}
			></div>
			<SimpleMap lat={props.lat} lng={props.lng} text="Some Text" />
		</div>
	</div>
);

export default MapView;
