import React, {Component, createRef} from "react";
import ReactDOM from "react-dom";
//import scss
import "./Portal.scss";

// import Scrollbars from "react-custom-scrollbars";

class Portal extends Component {
	constructor(props) {
		super(props);
		this.rootSelector = document.getElementById("root");
		this.container = document.createElement("div");
		this.container.classList.add("popup");
		this.popupRef = createRef(null);
	}
	componentDidMount() {
		this.rootSelector.appendChild(this.container);
	}

	componentWillUnmount() {
		this.rootSelector.removeChild(this.container);
	}

	render() {
		return ReactDOM.createPortal(
			<>
				<>{this.props.children}</>
			</>,
			this.container
		);
	}
}

export default Portal;
