export const DeleteSVG = (props) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#deleteSVG)">
			<path
				d="M12.7593 3.07861L11.7392 14.7028H4.26067L3.2408 3.07861L1.94849 3.19189L2.98699 15.0269C3.04164 15.5724 3.51496 16.0001 4.0648 16.0001H11.9351C12.4847 16.0001 12.9582 15.5726 13.0138 15.0191L14.0516 3.19189L12.7593 3.07861Z"
				fill="#222F33"
				fillOpacity="0.5"
			/>
			<path
				d="M10.3784 0H5.62162C5.0255 0 4.54053 0.484969 4.54053 1.08109V3.13516H5.83781V1.29728H10.1621V3.13512H11.4594V1.08106C11.4595 0.484969 10.9745 0 10.3784 0Z"
				fill="#222F33"
				fillOpacity="0.5"
			/>
			<path
				d="M14.9191 2.48657H1.08127C0.722992 2.48657 0.432617 2.77695 0.432617 3.13523C0.432617 3.49351 0.722992 3.78388 1.08127 3.78388H14.9191C15.2774 3.78388 15.5678 3.49351 15.5678 3.13523C15.5678 2.77695 15.2774 2.48657 14.9191 2.48657Z"
				fill="#222F33"
				fillOpacity="0.5"
			/>
		</g>
		<defs>
			<clipPath id="deleteSVG">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
