const ORSVG = (props) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.6562 8.37974H12.0901C11.8769 8.37974 11.6705 8.40887 11.4741 8.46243C11.0867 7.7008 10.2957 7.17749 9.38422 7.17749H6.61578C5.70431 7.17749 4.91331 7.7008 4.52588 8.46243C4.32947 8.40887 4.12309 8.37974 3.90994 8.37974H2.34375C1.05141 8.37974 0 9.43115 0 10.7235V13.2272C0 14.0026 0.630844 14.6334 1.40625 14.6334H14.5938C15.3692 14.6334 16 14.0026 16 13.2272V10.7235C16 9.43115 14.9486 8.37974 13.6562 8.37974ZM4.27203 9.5213V13.696H1.40625C1.14778 13.696 0.9375 13.4857 0.9375 13.2272V10.7235C0.9375 9.94812 1.56834 9.31727 2.34375 9.31727H3.90994C4.03719 9.31727 4.16041 9.33455 4.27772 9.3664C4.27434 9.41768 4.27203 9.46924 4.27203 9.5213ZM10.7905 13.696H5.20953V9.52127C5.20953 8.74587 5.84037 8.11502 6.61578 8.11502H9.38422C10.1596 8.11502 10.7905 8.74587 10.7905 9.52127V13.696ZM15.0625 13.2272C15.0625 13.4857 14.8522 13.696 14.5938 13.696H11.728V9.52127C11.728 9.46918 11.7257 9.41762 11.7223 9.36637C11.8396 9.33455 11.9628 9.31724 12.0901 9.31724H13.6562C14.4317 9.31724 15.0625 9.94808 15.0625 10.7235V13.2272Z"
			fill="#2E4C79"
		/>
		<path
			d="M3.12663 3.95337C1.97823 3.95337 1.04395 4.88765 1.04395 6.03606C1.04391 7.18446 1.97823 8.11874 3.12663 8.11874C4.27501 8.11874 5.20932 7.18446 5.20932 6.03606C5.20932 4.88765 4.27504 3.95337 3.12663 3.95337ZM3.1266 7.18124C2.49513 7.18124 1.98141 6.66753 1.98141 6.03606C1.98141 5.40459 2.49513 4.89087 3.1266 4.89087C3.75807 4.89087 4.27179 5.40459 4.27179 6.03606C4.27179 6.66753 3.75807 7.18124 3.1266 7.18124Z"
			fill="#2E4C79"
		/>
		<path
			d="M8.00021 1.36646C6.46596 1.36646 5.21777 2.61464 5.21777 4.14889C5.21777 5.68314 6.46596 6.93133 8.00021 6.93133C9.53446 6.93133 10.7826 5.68314 10.7826 4.14889C10.7826 2.61467 9.53446 1.36646 8.00021 1.36646ZM8.00021 5.99383C6.9829 5.99383 6.15527 5.1662 6.15527 4.14889C6.15527 3.13161 6.9829 2.30395 8.00021 2.30395C9.01752 2.30395 9.84515 3.13158 9.84515 4.14889C9.84515 5.1662 9.01752 5.99383 8.00021 5.99383Z"
			fill="#2E4C79"
		/>
		<path
			d="M12.8732 3.95337C11.7248 3.95337 10.7905 4.88765 10.7905 6.03606C10.7906 7.18446 11.7248 8.11874 12.8732 8.11874C14.0216 8.11874 14.9559 7.18446 14.9559 6.03606C14.9559 4.88765 14.0216 3.95337 12.8732 3.95337ZM12.8732 7.18124C12.2418 7.18124 11.728 6.66753 11.728 6.03606C11.7281 5.40459 12.2418 4.89087 12.8732 4.89087C13.5047 4.89087 14.0184 5.40459 14.0184 6.03606C14.0184 6.66753 13.5047 7.18124 12.8732 7.18124Z"
			fill="#2E4C79"
		/>
	</svg>
);

export default ORSVG;
