import axios from "axios";
import cloneDeep from "clone-deep";
import { useEffect, useState } from "react";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { DeleteSVG } from "../../assets/svg/delete.svg";
import { EditSVG } from "../../assets/svg/edit.svg";
import AddProperty from "./add-property/AddProperty";
import styles from "./Admin.module.scss";

const AdminPage = (props) => {
  const [properties, setProperties] = useState([]);
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);

  const adminAuth = async () => {
    let auth = await axios.post(`${process.env.REACT_APP_AF_BASE_URL}/auth`, {
      pass: pass,
    });
    if (auth?.data === "ok") {
      localStorage.setItem("isAdmin", pass);
      setIsAdmin(pass);
    } else {
      setError(true);
    }
  };
  const getProperty = async () => {
    let data = await axios.get(
      `${process.env.REACT_APP_AF_BASE_URL}/getProperty`
    );

    setProperties(data.data);
  };

  const deleteProperty = async (id, i) => {
    await axios.delete(
      `${process.env.REACT_APP_AF_BASE_URL}/deleteProperty/${id}`
    );
    let clonedProperties = cloneDeep(properties);
    clonedProperties.splice(i, 1);
    setProperties(clonedProperties);
  };

  useEffect(() => {
    getProperty();
  }, []);
  return (
    <div className={styles.adminPage}>
      {isAdmin ? (
        <>
          <div className={styles.listWrapper}>
            {properties.map((prop, idx) => (
              <div key={idx} className={`df acsa ${styles.propLine}`}>
                <p className={`ffsb fs12 ${styles.name}`}>
                  {prop.propertyName}
                </p>
                <Link
                  target="_blank"
                  className={`ffsr fs12`}
                  to={`/portfolio/${prop._id}`}
                >
                  view page
                </Link>

                <div className={`df acsa mla actions`}>
                  <Link
                    to={`/admin/add/${prop._id}`}
                    className={`${styles.btn}`}
                  >
                    <EditSVG />
                  </Link>
                  <div
                    className={`${styles.btn}`}
                    onClick={() => deleteProperty(prop._id, idx)}
                  >
                    <DeleteSVG />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* component={(props) => <AddProperty {...props} />} */}
          <Route
            path="/admin/add/:id?"
            component={(props) => (
              <AddProperty {...props} refresh={getProperty} />
            )}
          ></Route>
        </>
      ) : (
        <div>
          <div className={styles.overlay}>
            <div className={`df ffc  acsa ${styles.popup}`}>
              <h3 className={`fflr fs18  ${error ? styles.error : ""}`}>
                Please enter your admin password to edit your portfolio
              </h3>
              <input
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                type="password"
                className={`${styles.input} ${error ? styles.error : ""}`}
              />

              <div
                className={`ffsm fs16 df acc ${styles.submit}`}
                onClick={() => adminAuth()}
              >
                Login
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
