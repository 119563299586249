import React from "react";

export const MenuCloseSVG = (props) => {
	return (
		<svg
			width="28"
			height="29"
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.49392 24.7075L25.4676 6.5586C25.9401 6.14969 25.8839 5.30966 25.3421 4.68353C24.8003 4.05741 23.9771 3.88111 23.5045 4.29002L2.53087 22.4389C2.05832 22.8478 2.11453 23.6878 2.65633 24.314C3.19813 24.9401 4.02137 25.1164 4.49392 24.7075Z"
				fill="#F2C94C"
			/>
			<path
				d="M25.8138 21.9115L4.04249 4.72761C3.55196 4.34044 2.73752 4.55371 2.22453 5.20365C1.71153 5.85359 1.69329 6.6953 2.18382 7.08246L23.9551 24.2664C24.4456 24.6536 25.2601 24.4403 25.7731 23.7903C26.2861 23.1404 26.3043 22.2987 25.8138 21.9115Z"
				fill="#F2C94C"
			/>
		</svg>
	);
};
