import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";
import { ImageUploadSVG } from "../../../assets/svg/image-upload.svg";
import styles from "./AddProperty.module.scss";
import UploadImage from "./upload-image/UploadImage";

const cloneDeep = require("clone-deep");

const PropertySchema = Yup.object().shape({
  propertyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Property name is required"),
  propertyAddress: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Property address is required"),
  shortDescription: Yup.string()
    .min(2, "Too Short!")
    .max(200, "Too Long!")
    .required("Description is required"),
  propertyType: Yup.string().required("Select a type"),
  squareFootage: Yup.string().required("Square footage is required"),
  // occupancyRate: Yup.string().required("Occupancy rate is required"),
  propertyLat: Yup.string().required("Lat is required"),
  propertyLong: Yup.string().required("Long is required"),
});

const AddProperty = (props) => {
  const history = useHistory();
  const params = useParams();

  const [images, setImages] = useState([
    { idx: 0 },
    { idx: 1 },
    { idx: 2 },
    { idx: 3 },
    { idx: 4 },
    { idx: 5 },
    { idx: 6 },
    { idx: 7 },
  ]);

  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState(undefined);

  const setImageAction = (data) => {
    let clone = cloneDeep(images);
    clone[data.idx].base64 = data.base64Image;

    setImages(clone);
  };

  const removeImage = (idx) => {
    let clone = cloneDeep(images);
    clone[idx] = { idx };
    setImages(clone);
  };

  const getProperty = async () => {
    let prop = await axios.get(
      `${process.env.REACT_APP_AF_BASE_URL}/getProperty/${params.id}`
    );

    setImages(prop.data.imgs);
    if (prop?.data?.pdfURL) setFile({ name: "Change File" });

    setProperty(prop.data);
  };

  useEffect(() => {
    if (!property) return;
    setLoading(false);
  }, [property]);

  useEffect(() => {
    document.body.classList.add("modal-open");
    if (params.id) {
      getProperty();
    } else {
      setLoading(false);
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
    // eslint-disable-next-line
  }, []);
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(undefined);

  const fileSelected = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (file === null || file?.name === "Change File") return;

    fileUploadHandler();
    // eslint-disable-next-line
  }, [file]);

  const fileUploadHandler = async (index) => {
    setDisabled(true);
    let selectedFile = file;
    var fileReader = new FileReader();
    var base64;

    fileReader.onload = (fileLoadedEvent) => {
      base64 = fileLoadedEvent.target.result;

      const i = base64.indexOf("base64,");
      const buffer = Buffer.from(base64.slice(i + 7), "base64");
      const name = selectedFile.name;

      const file = new File([buffer], name, { type: selectedFile.type });

      const fd = new FormData();
      fd.append("file", file);
      fd.append("fileName", file.name);

      axios
        .post(`${process.env.REACT_APP_AF_BASE_URL}/uploadPDF`, fd, {
          headers: {
            "content-type": "multipart/form-data",
            "file-name": file.name,
          },
        })
        .then((filename) => {
          setFileUrl(filename.data);
          setDisabled(false);
        });
    };
    fileReader.readAsDataURL(selectedFile);
  };

  return (
    <>
      <div className={styles.AddPropertyPage}>
        {loading ? (
          "..."
        ) : (
          <>
            <div
              className={`df acc ${styles.closeBtn}`}
              onClick={() => history.push("/admin")}
            ></div>
            <Formik
              validationSchema={PropertySchema}
              initialValues={{
                propertyName: property?.propertyName || "",
                propertyAddress: property?.propertyAddress || "",
                shortDescription: property?.shortDescription || "",
                propertyType: property?.propertyType || "residential",
                squareFootage: property?.squareFootage || "",
                occupancyRate: property?.occupancyRate || "",
                propertyLat: property?.propertyLat || "",
                propertyLong: property?.propertyLong || "",
                featuredTenants: property?.featuredTenants || "",
              }}
              onSubmit={async (values) => {
                setDisabled(true);
                let clone = cloneDeep(images);

                let withStatus = await clone.map((img) => ({
                  ...img,
                  status: img.base64 ? "pending" : undefined,
                }));
                setImages(withStatus);

                let imgs = await Promise.all(
                  clone.map(async (img) => {
                    if (img.base64) {
                      let res = await axios
                        .post(
                          `${process.env.REACT_APP_AF_BASE_URL}/uploadImage`,
                          {
                            file: img.base64,
                          }
                        )
                        .catch((e) => e);

                      return {
                        ...img,
                        status: "done",
                        filePath: res.data.filePath,
                        imageBaseUrl: "https://ik.imagekit.io/qimtirncjhjmy",
                        base64: null,
                      };
                    } else {
                      return { ...img };
                    }
                  })
                );

                setImages(imgs);

                values.imgs = imgs;
                values.pdfURL = props.pdfURL || fileUrl;

                if (!property?._id) {
                  await axios.post(
                    `${process.env.REACT_APP_AF_BASE_URL}/addProperty`,
                    {
                      values,
                    }
                  );
                } else {
                  await axios.put(
                    `${process.env.REACT_APP_AF_BASE_URL}/addProperty/${property._id}`,
                    {
                      values,
                    }
                  );
                }
                setDisabled(false);
                props.refresh();
                history.push("/admin");
              }}
            >
              {({ errors, values, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Property Name</label>
                    <Field
                      id="propertyName"
                      name="propertyName"
                      placeholder="Property Name"
                    />
                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.propertyName && touched.propertyName ? (
                        <span>{errors.propertyName}</span>
                      ) : null}
                    </p>
                  </div>
                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Property Address</label>
                    <Field
                      id="propertyAddress"
                      name="propertyAddress"
                      placeholder="Property Address"
                    />
                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.propertyAddress && touched.propertyAddress ? (
                        <span>{errors.propertyAddress}</span>
                      ) : null}
                    </p>
                  </div>

                  <div
                    className={`df acsa ${styles.inputWrpr} ${styles.multi}`}
                  >
                    <div className={styles.fieldWrpr}>
                      <label className={`ffsr fs16`}>Latitude</label>
                      <Field
                        id="propertyLat"
                        name="propertyLat"
                        placeholder="Latitude"
                      />
                      <p className={`ffsr ${styles.errorMsg}`}>
                        {errors.propertyLat && touched.propertyLat ? (
                          <span>{errors.propertyLat}</span>
                        ) : null}
                      </p>
                    </div>

                    <div className={styles.fieldWrpr}>
                      <label className={`ffsr fs16`}>Longitude</label>
                      <Field
                        id="propertyLong"
                        name="propertyLong"
                        placeholder="Longitude"
                      />
                      <p className={`ffsr ${styles.errorMsg}`}>
                        {errors.propertyLong && touched.propertyLong ? (
                          <span>{errors.propertyLong}</span>
                        ) : null}
                      </p>
                    </div>
                  </div>

                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Short Description</label>
                    <Field
                      id="shortDescription"
                      name="shortDescription"
                      placeholder="Short Description"
                      as="textarea"
                    />

                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.shortDescription && touched.shortDescription ? (
                        <span>{errors.shortDescription}</span>
                      ) : null}
                    </p>
                  </div>

                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Property Type</label>
                    {/* <Field as="select" name="propertyType">
                      <option value="residential">Residential</option>
                      <option value="retail">Retail</option>
                    </Field> */}
                    <Field
                      id="propertyType"
                      name="propertyType"
                      placeholder="Property Type"
                    />
                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.propertyType && touched.propertyType ? (
                        <span>{errors.propertyType}</span>
                      ) : null}
                    </p>
                  </div>

                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Square Footage</label>
                    <Field
                      id="squareFootage"
                      name="squareFootage"
                      placeholder="Square Footage"
                    />
                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.squareFootage && touched.squareFootage ? (
                        <span>{errors.squareFootage}</span>
                      ) : null}
                    </p>
                  </div>

                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Occupancy Rate</label>
                    <Field
                      id="occupancyRate"
                      name="occupancyRate"
                      placeholder="Occupancy Rate"
                    />
                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.occupancyRate && touched.occupancyRate ? (
                        <span>{errors.occupancyRate}</span>
                      ) : null}
                    </p>
                  </div>

                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Featured Tenants</label>
                    <Field
                      id=""
                      name="featuredTenants"
                      placeholder="Featured Tenants"
                      as="textarea"
                    />

                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.featuredTenants && touched.featuredTenants ? (
                        <span>{errors.featuredTenants}</span>
                      ) : null}
                    </p>
                  </div>
                  <div className={styles.thumbsWrapper}>
                    {images.map((img) => {
                      return (
                        <UploadImage
                          key={img.idx}
                          idx={img.idx}
                          setImages={setImageAction}
                          status={img.status}
                          removeImage={removeImage}
                          imgUrl={
                            img.imageBaseUrl
                              ? `${img.imageBaseUrl}${img.filePath}`
                              : null
                          }
                        />
                      );
                    })}
                  </div>

                  {/* Site Plan */}

                  <div className={styles.inputWrpr}>
                    <label className={`ffsr fs16`}>Site Plan</label>
                    <label
                      htmlFor="pdfUpload"
                      className={`df acc ${styles.pdfUploadLbl}`}
                    >
                      {!file ? <ImageUploadSVG /> : <>{file.name}</>}
                    </label>

                    <input
                      id="pdfUpload"
                      style={{ display: "none" }}
                      type="file"
                      accept=".pdf,.jpg,.png,.jpeg"
                      onChange={(e) => fileSelected(e, setFieldValue)}
                    />
                    <p className={`ffsr ${styles.errorMsg}`}>
                      {errors.featuredTenants && touched.featuredTenants ? (
                        <span>{errors.featuredTenants}</span>
                      ) : null}
                    </p>
                  </div>

                  <button
                    className={styles.submit}
                    type="submit"
                    disabled={disabled}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
      <div className={styles.backdrop} />
    </>
  );
};

export default AddProperty;
