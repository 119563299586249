import { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { ImageUploadSVG } from "../../../../assets/svg/image-upload.svg";
import UploadPopup from "./upload-popup/UploadPopup";
import styles from "./UploadImage.module.scss";

const UploadImage = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    props.setImages({ base64Image, idx: props.idx });
    // eslint-disable-next-line
  }, [base64Image]);

  useEffect(() => {
    setImageBlob(props.imgUrl);
  }, [props.imgUrl]);

  const setImageBlobAction = (d) => {
    setImageBlob(d);
  };

  return (
    <div>
      <div
        className={`df acc ${styles.thumbWrapper} ${
          imageBlob ? styles.hasImg : ""
        }`}
        onClick={() => setShowPopup(true)}
      >
        {props.imgUrl ? (
          <p
            className={`df acc ffsr ${styles.delete}`}
            onClick={(e) => {
              e.stopPropagation();
              props.removeImage(props.idx);
            }}
          >
            x
          </p>
        ) : undefined}

        {/* <p
					style={{
						position: "absolute",
						zIndex: 100000,
						color: "red",
						left: "0px",
						top: "0px",
					}}
				>
					{props.status}
				</p> */}
        {props.status === "pending" ? (
          <div className={styles.loader} />
        ) : props.status === "done" ? (
          <div className={styles.done} />
        ) : undefined}
        {imageBlob ? <img src={imageBlob} alt="img" /> : <ImageUploadSVG />}
      </div>
      {showPopup ? (
        <UploadPopup
          close={setShowPopup}
          setBase64Image={setBase64Image}
          setImageBlob={setImageBlobAction}
          imageUrl={props.imgUrl}
        />
      ) : undefined}
    </div>
  );
};

export default UploadImage;
