const SFSVG = (props) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#sfimage)">
			<path
				d="M1.86351 7.25134C1.93098 7.26097 1.99832 7.26572 2.06529 7.26572C2.22501 7.26572 2.38204 7.23831 2.53101 7.18547V12.3438C2.53101 12.6026 2.74089 12.8125 2.99976 12.8125H12.9998C13.2586 12.8125 13.4685 12.6026 13.4685 12.3438V7.18544C13.6174 7.23813 13.7749 7.2655 13.9365 7.2655C14.3773 7.2655 14.7982 7.05519 15.0622 6.703C15.2876 6.40253 15.3825 6.03225 15.3294 5.66034C15.2763 5.28844 15.0814 4.9595 14.7809 4.73416L13.4685 3.74978V0.46875C13.4685 0.209875 13.2586 0 12.9998 0H11.1248C10.8659 0 10.656 0.209875 10.656 0.46875V1.64038L8.84323 0.280813C8.34657 -0.0911875 7.65288 -0.0911875 7.15607 0.280969L1.2186 4.73409C0.918073 4.95947 0.723261 5.28841 0.670167 5.66031C0.617042 6.03222 0.711917 6.4025 0.937229 6.70284C1.16257 7.00344 1.49154 7.19825 1.86351 7.25134ZM3.46851 11.875V6.56244L7.99976 3.16394L12.531 6.56244V11.875H3.46851ZM11.5935 0.9375H12.531V3.04666L11.5935 2.34353V0.9375ZM1.59826 5.79287C1.61595 5.66887 1.68092 5.55922 1.7811 5.48409L7.71835 1.03116C7.8012 0.969063 7.90054 0.938062 7.99982 0.938062C8.09907 0.938062 8.19826 0.969063 8.28095 1.031L14.2185 5.48413C14.3186 5.55925 14.3836 5.66891 14.4013 5.79287C14.419 5.91684 14.3874 6.04025 14.3122 6.1405C14.2229 6.25966 14.0859 6.328 13.9365 6.328C13.8348 6.328 13.7378 6.29556 13.6561 6.23438L8.28101 2.20297C8.11432 2.07797 7.8852 2.07797 7.71851 2.20297L2.34351 6.23431C2.24339 6.30937 2.11985 6.34088 1.99601 6.32328C1.87204 6.30559 1.76242 6.24066 1.68726 6.14038C1.61217 6.04025 1.58054 5.91684 1.59826 5.79287Z"
				fill="#2E4C79"
			/>
			<path
				d="M15.8632 13.9498L14.6132 12.6998C14.4302 12.5168 14.1333 12.5168 13.9503 12.6998C13.7672 12.8829 13.7672 13.1797 13.9503 13.3627L14.4001 13.8125H1.60088L2.05069 13.3627C2.23375 13.1796 2.23375 12.8829 2.05069 12.6998C1.86766 12.5167 1.57085 12.5167 1.38779 12.6998L0.137785 13.9498C-0.0452773 14.1329 -0.0452773 14.4296 0.137785 14.6127L1.38779 15.8627C1.47932 15.9543 1.59925 16 1.71922 16C1.83919 16 1.95916 15.9543 2.05066 15.8627C2.23372 15.6796 2.23372 15.3829 2.05066 15.1998L1.60088 14.75H14.4001L13.9503 15.1998C13.7672 15.3829 13.7672 15.6797 13.9503 15.8627C14.0418 15.9543 14.1618 16 14.2817 16C14.4017 16 14.5217 15.9543 14.6132 15.8627L15.8632 14.6127C16.0463 14.4297 16.0463 14.1329 15.8632 13.9498Z"
				fill="#2E4C79"
			/>
			<path
				d="M10.8125 6.5625H5.1875C4.92862 6.5625 4.71875 6.77237 4.71875 7.03125V9.84375C4.71875 10.1026 4.92862 10.3125 5.1875 10.3125H10.8125C11.0714 10.3125 11.2812 10.1026 11.2812 9.84375V7.03125C11.2812 6.77237 11.0714 6.5625 10.8125 6.5625ZM5.65625 7.5H7.53125V9.375H5.65625V7.5ZM10.3437 9.375H8.46875V7.5H10.3437V9.375Z"
				fill="#2E4C79"
			/>
		</g>
		<defs>
			<clipPath id="sfimage">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default SFSVG;
