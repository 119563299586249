const FTSVG = (props) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.5 0H3.5C1.567 0 0 1.567 0 3.5V12.5C0 14.433 1.567 16 3.5 16H12.5C14.433 16 16 14.433 16 12.5V3.5C16 1.567 14.433 0 12.5 0ZM15 12.5C15 13.8807 13.8807 15 12.5 15H3.5C2.11928 15 1 13.8807 1 12.5V3.5C1 2.11928 2.11928 1 3.5 1H12.5C13.8807 1 15 2.11928 15 3.5V12.5Z"
			fill="#2E4C79"
		/>
		<path
			d="M5 3.5H4C3.72384 3.5 3.5 3.72384 3.5 4V5C3.5 5.27616 3.72384 5.5 4 5.5H5C5.27616 5.5 5.5 5.27616 5.5 5V4C5.5 3.72384 5.27616 3.5 5 3.5Z"
			fill="#2E4C79"
		/>
		<path
			d="M5 7H4C3.72384 7 3.5 7.22384 3.5 7.5V8.5C3.5 8.77616 3.72384 9 4 9H5C5.27616 9 5.5 8.77616 5.5 8.5V7.5C5.5 7.22384 5.27616 7 5 7Z"
			fill="#2E4C79"
		/>
		<path
			d="M5 10.5H4C3.72384 10.5 3.5 10.7238 3.5 11V12C3.5 12.2762 3.72384 12.5 4 12.5H5C5.27616 12.5 5.5 12.2762 5.5 12V11C5.5 10.7238 5.27616 10.5 5 10.5Z"
			fill="#2E4C79"
		/>
		<path d="M12.5 4H6.5V5H12.5V4Z" fill="#2E4C79" />
		<path d="M12.5 7.5H6.5V8.5H12.5V7.5Z" fill="#2E4C79" />
		<path d="M12.5 11H6.5V12H12.5V11Z" fill="#2E4C79" />
	</svg>
);

export default FTSVG;
